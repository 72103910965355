import { useEffect } from "react";
import { useParams } from "react-router";

import { AUTHORIZATION_KEY, DEVICE_ID } from "../../global_constants";

import { INDEX_ROUTE } from "../../routes";

const DirectUse = () => {
  const params = useParams();
  const { jwt } = params;
  const { adminJwt } = params;

  useEffect(() => {
    if (jwt && adminJwt) {
      localStorage.setItem(AUTHORIZATION_KEY, jwt);
      localStorage.setItem(DEVICE_ID, adminJwt);

      // refresh to dashboard (root)
      window.location.href = INDEX_ROUTE;
    }
  }, [jwt, adminJwt]);

  return (
    <div className="login-page">
      Direct Use... Please wait...
    </div>
  );
};
export default DirectUse;
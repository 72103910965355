import instance from "../axios";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY, DEVICE_ID } from "../global_constants";

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAuthUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (!token) {
      reject();
      return;
    }

    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // important for a specific reason - inquire with the backend team
    instance.defaults.headers.common["X-Device-Id"] = localStorage.getItem(DEVICE_ID);
    instance
      .get(endpoints.AUTH_USER_API_PATH)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllManifests = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_MANIFESTS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`;
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getAllManifestsUnpaginated = () => {
  let path = endpoints.GET_ALL_MANIFESTS_UNPAGINATED_API_PATH;
  return instance.get(path);
};
export const getManifestsStats = (manifestIds) => {
  let path = endpoints.GET_MANIFESTS_STATS_API_PATH.replace("{manifestIds}", manifestIds);
  return instance.get(path);
};
export const getSingleManifest = (manifestId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.get(path);
};
export const getSingleManifestLastLot = (manifestId) => {
  let path = endpoints.GET_MANIFEST_LAST_LOT_API_PATH.replace("{manifestId}", manifestId);
  return instance.get(path);
};
export const setIsHiddenManifest = (manifestId, isHidden) => {
  let path = endpoints.SET_IS_HIDDEN_MANIFEST_API_PATH.replace("{manifestId}", manifestId).replace("{isHidden}", isHidden);
  return instance.get(path);
};
export const addManifest = (payload) => {
  let path = endpoints.ADD_MANIFEST_API_PATH;
  return instance.post(path, payload);
};
export const editManifest = (payload, manifestId) => {
  let path = endpoints.EDIT_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.post(path, payload);
};
export const deleteManifest = (manifestId) => {
  let path = endpoints.DELETE_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.delete(path);
};

export const getAllManifestGroups = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_MANIFEST_GROUPS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`;
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getAllManifestGroupsUnpaginated = () => {
  let path = endpoints.GET_ALL_MANIFEST_GROUPS_UNPAGINATED_API_PATH;
  return instance.get(path);
};
export const getManifestGroupsStats = (manifestGroupIds) => {
  let path = endpoints.GET_MANIFEST_GROUPS_STATS_API_PATH.replace("{manifestGroupIds}", manifestGroupIds);
  return instance.get(path);
};
export const getSingleManifestGroup = (manifestGroupId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.get(path);
};
export const setIsHiddenManifestGroup = (manifestGroupId, isHidden) => {
  let path = endpoints.SET_IS_HIDDEN_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId).replace("{isHidden}", isHidden);
  return instance.get(path);
};
export const addManifestGroup = (payload) => {
  let path = endpoints.ADD_MANIFEST_GROUP_API_PATH;
  return instance.post(path, payload);
};
export const editManifestGroup = (payload, manifestGroupId) => {
  let path = endpoints.EDIT_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.post(path, payload);
};
export const deleteManifestGroup = (manifestGroupId) => {
  let path = endpoints.DELETE_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.delete(path);
};

export const getAllVendors = (params = null) => {
  let path = endpoints.GET_ALL_VENDORS_API_PATH;
  if (params) path = path + "?" + params;
  return instance.get(path);
};
export const getSingleVendor = (vendorId) => {
  let path = endpoints.GET_SINGLE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.get(path);
};
export const addVendor = (payload) => {
  let path = endpoints.ADD_VENDOR_API_PATH;
  return instance.post(path, payload);
};
export const editVendor = (payload, vendorId) => {
  let path = endpoints.EDIT_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.post(path, payload);
};
export const deleteVendor = (vendorId) => {
  let path = endpoints.DELETE_VENDOR_API_PATH.replace("{vendorId}", vendorId);
  return instance.delete(path);
};
export const setIsHiddenVendor = (vendorId, isHidden) => {
  let path = endpoints.SET_IS_HIDDEN_VENDOR_API_PATH.replace("{vendorId}", vendorId).replace("{isHidden}", isHidden);
  return instance.get(path);
};

export const getAllZplTemplates = () => {
  let path = endpoints.GET_ALL_ZPL_TEMPLATES_API_PATH;
  return instance.get(path);
};
export const getSingleZplTemplate = (zplTemplateId) => {
  let path = endpoints.GET_SINGLE_ZPL_TEMPLATE_API_PATH.replace("{zplTemplateId}", zplTemplateId);
  return instance.get(path);
};
export const addZplTemplate = (payload) => {
  let path = endpoints.ADD_ZPL_TEMPLATE_API_PATH;
  return instance.post(path, payload);
};
export const editZplTemplate = (payload, zplTemplateId) => {
  let path = endpoints.EDIT_ZPL_TEMPLATE_API_PATH.replace("{zplTemplateId}", zplTemplateId);
  return instance.post(path, payload);
};
export const deleteZplTemplate = (zplTemplateId) => {
  let path = endpoints.DELETE_ZPL_TEMPLATE_API_PATH.replace("{zplTemplateId}", zplTemplateId);
  return instance.delete(path);
};

export const getAllDataIntegrations = () => {
  let path = endpoints.GET_ALL_DATA_INTEGRATIONS_API_PATH;
  return instance.get(path);
};

export const getAllDataSettings = () => {
  let path = endpoints.GET_ALL_DATA_SETTINGS_API_PATH;
  return instance.get(path);
};
export const getAllDataSettingsWithUploadedManifestsManifest = (manifestId) => {
  let path = endpoints.GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_API_PATH.replace("{manifestId}", manifestId);
  return instance.get(path);
};
export const getAllDataSettingsWithUploadedManifestsManifestGroup = (manifestGroupId) => {
  let path = endpoints.GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_GROUP_API_PATH.replace("{manifestGroupId}", manifestGroupId);
  return instance.get(path);
};
export const getSingleDataSetting = (dataSettingId) => {
  let path = endpoints.GET_SINGLE_DATA_SETTING_API_PATH.replace("{dataSettingId}", dataSettingId);
  return instance.get(path);
};
export const addDataSetting = (payload) => {
  let path = endpoints.ADD_DATA_SETTING_API_PATH;
  return instance.post(path, payload);
};
export const editDataSetting = (payload, dataSettingId) => {
  let path = endpoints.EDIT_DATA_SETTING_API_PATH.replace("{dataSettingId}", dataSettingId);
  return instance.post(path, payload);
};
export const deleteDataSetting = (dataSettingId) => {
  let path = endpoints.DELETE_DATA_SETTING_API_PATH.replace("{dataSettingId}", dataSettingId);
  return instance.delete(path);
};

export const getAllUploadedManifests = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_UPLOADED_MANIFESTS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`;
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getAllUploadedManifestsUnpaginated = () => {
  let path = endpoints.GET_ALL_UPLOADED_MANIFESTS_UNPAGINATED_API_PATH;
  return instance.get(path);
};
export const getSingleUploadedManifest = (uploadedManifestId) => {
  let path = endpoints.GET_SINGLE_UPLOADED_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId);
  return instance.get(path);
};
export const addUploadedManifest = (manifestToUpload, getParams) => {
  let path = endpoints.ADD_UPLOADED_MANIFEST_API_PATH;

  let paramString = "";
  if (getParams) {
    paramString = new URLSearchParams(Object.keys(getParams).map((k) => [k, getParams[k]])).toString();
  }
  path = path + "?" + paramString;

  return instance.post(path, manifestToUpload, {
    headers: {
      "Content-Type": "multipart/form-data", // Important to specify the content type
    },
  });
};
export const editUploadedManifest = (payload, uploadedManifestId) => {
  let path = endpoints.EDIT_UPLOADED_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId);
  return instance.post(path, payload);
};
export const deleteUploadedManifest = (uploadedManifestId) => {
  let path = endpoints.DELETE_UPLOADED_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId);
  return instance.delete(path);
};

export const getAllUploadedManifestItems = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_UPLOADED_MANIFEST_ITEMS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`;
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleUploadedManifestItem = (uploadedManifestItemId) => {
  let path = endpoints.GET_SINGLE_UPLOADED_MANIFEST_ITEM_API_PATH.replace("{uploadedManifestItemId}", uploadedManifestItemId);
  return instance.get(path);
};
export const addUploadedManifestItemsToManifest = (payload, uploadedManifestId, manifestId, addMultipleMode) => {
  let path = endpoints.ADD_UPLOADED_MANIFEST_ITEMS_TO_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId)
    .replace("{manifestId}", manifestId)
    .replace("{addMultipleMode}", addMultipleMode);
  return instance.post(path, payload);
};
export const addAllUploadedManifestItemsToManifest = (payload, uploadedManifestId, manifestId, addMultipleMode) => {
  let path = endpoints.ADD_ALL_UPLOADED_MANIFEST_ITEMS_TO_MANIFEST_API_PATH.replace("{uploadedManifestId}", uploadedManifestId)
    .replace("{manifestId}", manifestId)
    .replace("{addMultipleMode}", addMultipleMode);
  return instance.post(path, payload);
};
export const editUploadedManifestItem = (payload, uploadedManifestItemId) => {
  let path = endpoints.EDIT_UPLOADED_MANIFEST_ITEM_API_PATH.replace("{uploadedManifestItemId}", uploadedManifestItemId);
  return instance.post(path, payload);
};
export const deleteUploadedManifestItem = (uploadedManifestItemId) => {
  let path = endpoints.DELETE_UPLOADED_MANIFEST_ITEM_API_PATH.replace("{uploadedManifestItemId}", uploadedManifestItemId);
  return instance.delete(path);
};

export const getMainDashboardUrl = () => {
  let path = endpoints.GET_DASHBOARD_API_PATH;
  return instance.get(path);
};

export const getAllManifestItems = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_MANIFEST_ITEMS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`;
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getAllManifestItemsNoPagination = (params = null) => {
  let path = endpoints.GET_ALL_MANIFEST_ITEMS_NO_PAGINATION_API_PATH;
  if (params) path = path + "?" + params;
  return instance.get(path);
};
export const getSingleManifestItem = (manifestItemId) => {
  let path = endpoints.GET_SINGLE_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.get(path);
};
export const getManifestItemPhotos = (manifestItemId) => {
  let path = endpoints.GET_MANIFEST_ITEM_PHOTOS_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.get(path);
};
export const getManifestItemBrands = () => {
  let path = endpoints.GET_MANIFEST_ITEM_BRANDS_API_PATH;
  return instance.get(path);
};
export const updateManifestItemQuantity = (manifestItemId, qty) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_QUANTITY_API_PATH.replace("{manifestItemId}", manifestItemId).replace("{qty}", qty);
  return instance.get(path);
};
export const getWalmartVariants = (manifestItemId) => {
  let path = endpoints.GET_WALMART_VARIANTS_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.get(path);
};
export const replaceWithWalmartVariant = (manifestItemId, walmartVariantId) => {
  let path = endpoints.REPLACE_WITH_WALMART_VARIANT_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId).replace(
    "{walmartVariantId}",
    walmartVariantId
  );
  return instance.get(path);
};
export const updateManifestItemVendor = (manifestItemId, vendorId) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_VENDOR_API_PATH.replace("{manifestItemId}", manifestItemId).replace("{vendorId}", vendorId);
  return instance.get(path);
};
export const updateManifestItemCategory = (manifestItemId, categoryId) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_CATEGORY_API_PATH.replace("{manifestItemId}", manifestItemId).replace("{categoryId}", categoryId);
  return instance.get(path);
};
export const updateManifestItemDepartment = (manifestItemId, departmentId) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_DEPARTMENT_API_PATH.replace("{manifestItemId}", manifestItemId).replace("{departmentId}", departmentId);
  return instance.get(path);
};
export const updateManifestItemProductCondition = (manifestItemId, productConditionId) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_PRODUCT_CONDITION_API_PATH.replace("{manifestItemId}", manifestItemId).replace(
    "{productConditionId}",
    productConditionId
  );
  return instance.get(path);
};
export const updateManifestItemNote = (manifestItemId, payload) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_NOTE_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.post(path, payload);
};
export const addManifestItem = (payload) => {
  let path = endpoints.ADD_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const addUpcManifestItem = (payload) => {
  let path = endpoints.ADD_UPC_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const addItemNumManifestItem = (payload) => {
  let path = endpoints.ADD_ITEM_NUM_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const addBundleManifestItem = (payload) => {
  let path = endpoints.ADD_BUNDLE_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const editManifestItem = (payload, manifestItemId) => {
  let path = endpoints.EDIT_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.post(path, payload);
};
export const getShortenedTitleManifestItem = (payload, manifestItemId) => {
  let path = endpoints.GET_SHORTENED_TITLE_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.post(path, payload);
};
export const updateManifestItemTitle = (manifestItemId, payload) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_TITLE_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.post(path, payload);
};
export const updateManifestItemDescription = (manifestItemId, payload) => {
  let path = endpoints.UPDATE_MANIFEST_ITEM_DESCRIPTION_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.post(path, payload);
};
export const addMultiplesDifferentManifest = (payload) => {
  let path = endpoints.ADD_MULTIPLES_DIFFERENT_MANIFEST_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const addMultiplesSameManifest = (payload) => {
  let path = endpoints.ADD_MULTIPLES_SAME_MANIFEST_MANIFEST_ITEM_API_PATH;
  return instance.post(path, payload);
};
export const deleteManifestItem = (manifestItemId) => {
  let path = endpoints.DELETE_MANIFEST_ITEM_API_PATH.replace("{manifestItemId}", manifestItemId);
  return instance.delete(path);
};

export const getAllProductConditions = () => {
  let path = endpoints.GET_ALL_PRODUCT_CONDITIONS_API_PATH;
  return instance.get(path);
};
export const getSingleProductCondition = (productConditionId) => {
  let path = endpoints.GET_SINGLE_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.get(path);
};
export const addProductCondition = (payload) => {
  let path = endpoints.ADD_PRODUCT_CONDITION_API_PATH;
  return instance.post(path, payload);
};
export const editProductCondition = (payload, productConditionId) => {
  let path = endpoints.EDIT_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.post(path, payload);
};
export const deleteProductCondition = (productConditionId) => {
  let path = endpoints.DELETE_PRODUCT_CONDITION_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.delete(path);
};

export const getAllProductConditionSettings = () => {
  let path = endpoints.GET_ALL_PRODUCT_CONDITION_SETTINGS_API_PATH;
  return instance.get(path);
};
export const getSingleProductConditionSetting = (productConditionSettingId) => {
  let path = endpoints.GET_SINGLE_PRODUCT_CONDITION_SETTING_API_PATH.replace("{productConditionSettingId}", productConditionSettingId);
  return instance.get(path);
};
export const setIsHiddenProductConditionSetting = (productConditionSettingId, value) => {
  let path = endpoints.SET_IS_HIDDEN_PRODUCT_CONDITION_SETTING_API_PATH.replace("{productConditionSettingId}", productConditionSettingId).replace(
    "{value}",
    value
  );
  return instance.get(path);
};
export const setIsReadDescriptionProductConditionSetting = (productConditionSettingId, value) => {
  let path = endpoints.SET_IS_READ_DESCRIPTION_SINGLE_PRODUCT_CONDITION_SETTING_API_PATH.replace(
    "{productConditionSettingId}",
    productConditionSettingId
  ).replace("{value}", value);
  return instance.get(path);
};
export const addProductConditionSetting = (productConditionId) => {
  let path = endpoints.CREATE_PRODUCT_CONDITION_SETTING_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.get(path);
};
export const editProductConditionSetting = (payload, getParams) => {
  let path = endpoints.EDIT_PRODUCT_CONDITION_SETTING_API_PATH;

  let paramString = "";
  if (getParams) {
    paramString = new URLSearchParams(Object.keys(getParams).map((k) => [k, getParams[k]])).toString();
  }
  path = path + "?" + paramString;

  return instance.post(path, payload, {
    headers: {
      "Content-Type": "multipart/form-data", // Important to specify the content type
    },
  });
};
export const deleteProductConditionSetting = (productConditionSettingId) => {
  let path = endpoints.DELETE_PRODUCT_CONDITION_SETTING_API_PATH.replace("{productConditionSettingId}", productConditionSettingId);
  return instance.delete(path);
};

export const getAllCategories = () => {
  let path = endpoints.GET_ALL_CATEGORIES_API_PATH;
  return instance.get(path);
};
export const getSingleCategory = (categoryId) => {
  let path = endpoints.GET_SINGLE_CATEGORY_API_PATH.replace("{categoryId}", categoryId);
  return instance.get(path);
};
export const addCategory = (payload) => {
  let path = endpoints.ADD_CATEGORY_API_PATH;
  return instance.post(path, payload);
};
export const editCategory = (payload, categoryId) => {
  let path = endpoints.EDIT_CATEGORY_API_PATH.replace("{categoryId}", categoryId);
  return instance.post(path, payload);
};
export const deleteCategory = (categoryId) => {
  let path = endpoints.DELETE_CATEGORY_API_PATH.replace("{categoryId}", categoryId);
  return instance.delete(path);
};

export const getAllDepartments = () => {
  let path = endpoints.GET_ALL_DEPARTMENTS_API_PATH;
  return instance.get(path);
};
export const getSingleDepartment = (departmentId) => {
  let path = endpoints.GET_SINGLE_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.get(path);
};
export const addDepartment = (payload) => {
  let path = endpoints.ADD_DEPARTMENT_API_PATH;
  return instance.post(path, payload);
};
export const editDepartment = (payload, departmentId) => {
  let path = endpoints.EDIT_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.post(path, payload);
};
export const deleteDepartment = (departmentId) => {
  let path = endpoints.DELETE_DEPARTMENT_API_PATH.replace("{departmentId}", departmentId);
  return instance.delete(path);
};

export const startManifestDownload = (payload) => {
  let path = endpoints.MANIFEST_DOWNLOAD_API_PATH;
  return instance.post(path, payload);
};
export const startManifestGroupDownload = (payload) => {
  let path = endpoints.MANIFEST_GROUP_DOWNLOAD_API_PATH;
  return instance.post(path, payload);
};
export const startReceivingDownload = (payload) => {
  let path = endpoints.RECEIVING_DOWNLOAD_API_PATH;
  return instance.post(path, payload);
};

export const getAllDownloadLogs = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_DOWNLOAD_LOGS_API_PATH;
  path = path + `?page=${page}&limit=${limit}`;
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleDownloadLog = (downloadLogId) => {
  let path = endpoints.GET_SINGLE_DOWNLOAD_LOG_API_PATH.replace("{downloadLogId}", downloadLogId);
  return instance.get(path);
};
export const deleteDownloadLog = (downloadLogId) => {
  let path = endpoints.DELETE_DOWNLOAD_LOG_API_PATH.replace("{downloadLogId}", downloadLogId);
  return instance.delete(path);
};

export const getAccountSettings = () => {
  let path = endpoints.GET_ACCOUNT_SETTINGS_API_PATH;
  return instance.get(path);
};
export const editAccountSetting = (payload) => {
  let path = endpoints.EDIT_ACCOUNT_SETTINGS_API_PATH;
  return instance.post(path, payload);
};
export const setDefaultNewConditionAccountSetting = (productConditionId) => {
  let path = endpoints.SET_DEFAULT_NEW_PRODUCT_CONDITION_ACCOUNT_SETTINGS_API_PATH.replace("{productConditionId}", productConditionId);
  return instance.get(path);
};
export const setIsShowHiddenVendors = (value) => {
  let path = endpoints.SET_IS_SHOW_HIDDEN_VENDORS_API_PATH.replace("{value}", value);
  return instance.get(path);
};

export const getUsersDownloadSettings = () => {
  let path = endpoints.GET_DOWNLOAD_SETTINGS_API_PATH;
  return instance.get(path);
};

export const updateUsersDownloadSettings = (payload) => {
  let path = endpoints.UPDATE_DOWNLOAD_SETTINGS_API_PATH;
  return instance.post(path, payload);
};

export const getItemFieldConfigs = () => {
  let path = endpoints.GET_ALL_ITEM_FIELD_CONFIGS_API_PATH;
  return instance.get(path);
};
export const updateItemFieldConfigs = (payload) => {
  let path = endpoints.UPDATE_ALL_ITEM_FIELD_CONFIGS_API_PATH;
  return instance.post(path, payload);
};
export const itemFieldConfigSetAccountSettingsToAllUsers = () => {
  let path = endpoints.SET_ITEM_FIELD_CONFIGS_ACCOUNT_SETTINGS_TO_ALL_USERS_API_PATH;
  return instance.get(path);
};

export const getUserScanSetting = () => {
  let path = endpoints.GET_SCAN_SETTINGS_API_PATH;
  return instance.get(path);
};
export const toggleDefaultSetting = (defaultSetting) => {
  let path = endpoints.TOGGLE_DEFAULT_SETTING_API_PATH.replace("{defaultSetting}", defaultSetting);
  return instance.get(path);
};

export const getAllConditionFilters = () => {
  let path = endpoints.GET_ALL_CONDITION_FILTERS_API_PATH;
  return instance.get(path);
};
export const getAllConditionFiltersPaginated = (page = 1, limit = 10, params = null) => {
  let path = endpoints.GET_ALL_CONDITION_FILTERS_PAGINATED_API_PATH;
  path = path + `?page=${page}&limit=${limit}`;
  if (params) path = path + "&" + params;
  return instance.get(path);
};
export const getSingleConditionFilter = (conditionFilterId) => {
  let path = endpoints.GET_SINGLE_CONDITION_FILTER_API_PATH.replace("{conditionFilterId}", conditionFilterId);
  return instance.get(path);
};
export const addConditionFilter = (payload) => {
  let path = endpoints.ADD_CONDITION_FILTER_API_PATH;
  return instance.post(path, payload);
};
export const editConditionFilter = (payload, conditionFilterId) => {
  let path = endpoints.EDIT_CONDITION_FILTER_API_PATH.replace("{conditionFilterId}", conditionFilterId);
  return instance.post(path, payload);
};
export const deleteConditionFilter = (conditionFilterId) => {
  let path = endpoints.DELETE_CONDITION_FILTER_API_PATH.replace("{conditionFilterId}", conditionFilterId);
  return instance.delete(path);
};

export const getAllDownloadFieldConfigs = () => {
  let path = endpoints.GET_ALL_DOWNLOAD_FIELD_CONFIGS_API_PATH;
  return instance.get(path);
};
export const reorderAllDownloadFieldConfigs = (payload) => {
  let path = endpoints.REORDER_DOWNLOAD_FIELD_CONFIGS_API_PATH;
  return instance.post(path, payload);
};
export const editSingleDownloadFieldConfig = (payload, downloadFieldConfigId) => {
  let path = endpoints.EDIT_DOWNLOAD_FIELD_CONFIG_API_PATH.replace("{downloadFieldConfigId}", downloadFieldConfigId);
  return instance.post(path, payload);
};

export const getAllPalletDownloadFieldConfigs = () => {
  let path = endpoints.GET_ALL_PALLET_DOWNLOAD_FIELD_CONFIGS_API_PATH;
  return instance.get(path);
};
export const reorderAllPalletDownloadFieldConfigs = (payload) => {
  let path = endpoints.REORDER_PALLET_DOWNLOAD_FIELD_CONFIGS_API_PATH;
  return instance.post(path, payload);
};
export const editSinglePalletDownloadFieldConfig = (payload, downloadFieldConfigId) => {
  let path = endpoints.EDIT_PALLET_DOWNLOAD_FIELD_CONFIG_API_PATH.replace("{downloadFieldConfigId}", downloadFieldConfigId);
  return instance.post(path, payload);
};

export const getAllAccountUsers = (params = null) => {
  let path = endpoints.GET_ALL_ACCOUNT_USERS_API_PATH;
  if (params) path = path + "?" + params;
  return instance.get(path);
};
export const addUser = (payload) => {
  let path = endpoints.ADD_USER_API_PATH;
  return instance.post(path, payload);
};
export const editUser = (payload) => {
  let path = endpoints.EDIT_USER_API_PATH;
  return instance.post(path, payload);
};

export const getAllSupportTickets = () => {
  let path = endpoints.GET_ALL_SUPPORT_TICKETS_API_PATH;
  return instance.get(path);
};
export const getSingleSupportTicket = (supportTicketId) => {
  let path = endpoints.GET_SINGLE_SUPPORT_TICKET_API_PATH.replace("{supportTicketId}", supportTicketId);
  return instance.get(path);
};
export const getUnseenTickets = () => {
  let path = endpoints.GET_UNSEEN_TICKETS_API_PATH;
  return instance.get(path);
};
export const addSupportTicket = (payload) => {
  let path = endpoints.ADD_SUPPORT_TICKET_API_PATH;
  return instance.post(path, payload);
};
export const addSupportTicketComment = (supportTicketId, payload) => {
  let path = endpoints.ADD_SUPPORT_TICKET_COMMENT_API_PATH.replace("{supportTicketId}", supportTicketId);
  return instance.post(path, payload);
};

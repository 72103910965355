import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { Avatar, Col, Row, Space, Menu, Dropdown, Layout, message } from "antd";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import { AUTHORIZATION_KEY, DEVICE_ID } from "../../global_constants";
import { INDEX_ROUTE, SIDEBAR_ITEMS } from "../../routes";

import AccountHook from "../../hooks/account";
import RenderRoutes from "../../components/renderRoutes";
import { GetUserType } from "../../constants";
import loggerServiceInstance from "../../utilities/loggerService";
import * as Sentry from "@sentry/react";

import responseErrorService from "../../utilities/responseErrorHandling";
import { HardReloadFrontend } from "../../helpers";
import axiosInstance from "../../axios";
import StartDownloadModal from "../../components/startDownloadModal";
import { getUnseenTickets } from "../../actions";

const appVersion = require('../../../package.json').version;

const { Header, Content, Sider } = Layout;

const websocketOptionsDefinition = {
  share: false,
  reconnectAttempts: 10,
  reconnectInterval: 50000,
  // Will attempt to reconnect on all close events, such as server shutting down
  shouldReconnect: (closeEvent) => true,
  onReconnectStop: (numAttempts) => {
    Sentry.captureException(`WebSocket reconnection stopped after ${numAttempts} attempts`);
  },
  retryOnError: true,
} // reconnect every 50 secs

const DashboardLayout = ({
  routes,
  accountData,
  setAccountData,
  isUserAccountAdmin,
  isInventoryModuleEnabled
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});

  const [isDownloadReceivingItemsModalVisible, setIsDownloadReceivingItemsModalVisible] = useState(false);

  const [versionData, setVersionData] = useState(null);
  const [versionManagementWebSocketUrl, setVersionManagementWebSocketUrl] = useState(null);

  const [supportTicketsUnseen, setSupportTicketsUnseen] = useState(null);

  const { lastMessage: versionManagement } = useWebSocket(
    versionManagementWebSocketUrl,
    websocketOptionsDefinition
  );

  let userType = "";
  try {
    userType = GetUserType(accountData?.user_type);
    loggerServiceInstance.log("User Type: ", userType);
  } catch (error) {
    loggerServiceInstance.log(error);
  }

  const unseenTicketsCount = supportTicketsUnseen?.unseen_all?.length || 0;

  const refreshSupportTicketsUnseenData = () => {
    getUnseenTickets().then((response) => {
      const { data } = response;

      // if the value is the same, do not update the state
      try {
        if (JSON.stringify(data) === JSON.stringify(supportTicketsUnseen)) {
          return;
        }
      } catch { }
      setSupportTicketsUnseen(data);
    }).catch((err) => {

    });
  }

  const matchPathForSelectedMenuOption = (pathCompare, submenuItem) => {
    for (const submenuOption of submenuItem) {
      if (submenuOption.submenu !== undefined) {
        matchPathForSelectedMenuOption(pathCompare, submenuOption.submenu);
        continue;
      }
      const matchedPath = matchPath(pathCompare, {
        path: submenuOption.route,
        exact: true,
      });
      if (matchedPath) {
        if (selectedMenu.name !== submenuOption.name) {
          setSelectedMenu(submenuOption);
          return;
        }
      }
    }
  }

  const checkRole = (roleRequired) => {
    if (roleRequired && roleRequired !== userType) {
      return false;
    }
    return true;
  }


  const displaySubMenu = (submenuItem) => {
    if (!checkRole(submenuItem?.roleRequired)) {
      return null;
    }
    if (submenuItem.inventoryModuleRequired && !isInventoryModuleEnabled) {
      return null;
    }

    let isDownloadReceiving = submenuItem.downloadReceiving;
    let isExternalLink = submenuItem.isExternalLink;
    if (isExternalLink) {
      return (
        <Menu.Item
          key={submenuItem.label}
          icon={submenuItem.icon}
        >
          <a href={submenuItem.route} target="_blank" rel="noreferrer">{submenuItem.label}</a>
        </Menu.Item>
      )
    }
    if (isDownloadReceiving) {
      return (
        <Menu.Item
          key={submenuItem.name}
          icon={submenuItem.icon}
          onClick={() => setIsDownloadReceivingItemsModalVisible(true)}
        >
          {submenuItem.label}
        </Menu.Item>
      )
    }

    if (submenuItem.submenu) {
      return (
        <Menu.SubMenu
          key={submenuItem.name}
          title={
            <>
              {submenuItem.icon}
              <span>{submenuItem.label}</span>
            </>
          }
        >
          {submenuItem.submenu.map((submenuItem) => {
            return displaySubMenu(submenuItem);
          })}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item
        key={submenuItem.name}
        icon={submenuItem.icon}
        onClick={() => history.push(submenuItem.route)}
      >
        {submenuItem.label}
      </Menu.Item>
    );

  }

  const logout = () => {
    setAccountData({});
    history.push(INDEX_ROUTE);
    localStorage.removeItem(AUTHORIZATION_KEY);
  }

  const setAllWebsocketUrlsToNull = () => {
    setVersionManagementWebSocketUrl(null);
  }

  useEffect(() => {
    SIDEBAR_ITEMS.forEach((item) => {
      // matching child routes
      let pathCompare = pathname;
      let splittedPathname = pathname.split("/");
      if (splittedPathname.length > 2) {
        pathCompare = "/" + splittedPathname[1];
      }

      // if the route has submenus, we need to go through them
      if (item.submenu !== undefined) {
        matchPathForSelectedMenuOption(pathCompare, item.submenu);
      }

      const matchedPath = matchPath(pathCompare, {
        path: item.route,
        exact: true,
      });

      if (matchedPath) {
        if (selectedMenu.name !== item.name) {
          setSelectedMenu(item);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (versionData === null) return;
    if (versionData?.mfs_portal === undefined) return;
    if (versionData.mfs_portal === appVersion) return;

    alert("There is a new version of the MFS Portal available. Page should automatically refresh in 1 second after dismissing this alert. If it doesn't, please refresh the page to get the latest version!");
    setVersionManagementWebSocketUrl(null);
    HardReloadFrontend();

  }, [versionData])

  useEffect(() => {
    try {
      const data = versionManagement?.data;
      if (data === undefined) return;

      const parsedData = JSON.parse(data);
      setVersionData(parsedData)
    } catch (error) {
      responseErrorService.handle(error);
    }
  }, [versionManagement]);

  useEffect(() => {
    if (accountData?.id === undefined) {
      return;
    }

    let currentToken = axiosInstance.defaults.headers.common[
      "Authorization"
    ].replace("Bearer ", "");

    const baseUrl = process.env.REACT_APP_WEBSOCKET_HOST;
    const versionManagementWebsocketUrl = `${baseUrl}/api/v1/ws/frontend-version/info?token=${currentToken}`;

    setVersionManagementWebSocketUrl(versionManagementWebsocketUrl);

    return () => {
      setAllWebsocketUrlsToNull();
    }
  }, [accountData]);

  useEffect(() => {
    refreshSupportTicketsUnseenData();

    const interval = setInterval(() => {
      refreshSupportTicketsUnseenData();
    }, 40_000);

    return () => {
      clearInterval(interval);
    }
  }, [supportTicketsUnseen]);

  return (
    <>
      <Layout className="app-layout">
        <Sider trigger={null} collapsible collapsed={collapsed} width={225}>
          <div className="logo" style={{ backgroundColor: "black" }} />
          <Menu theme="dark" mode="inline" selectedKeys={[selectedMenu.name]}>
            {SIDEBAR_ITEMS.map((item) => {
              if (!checkRole(item?.roleRequired)) {
                return null;
              }
              if (item.inventoryModuleRequired && !isInventoryModuleEnabled) {
                return null;
              }

              let isExternalLink = item.isExternalLink;
              let isDownloadReceiving = item.downloadReceiving;
              if (isExternalLink) {
                return (
                  <Menu.Item
                    key={item.label}
                    icon={item.icon}
                  >
                    <a href={item.route} target="_blank" rel="noreferrer">{item.label}</a>
                  </Menu.Item>
                )
              }
              if (isDownloadReceiving) {
                return (
                  <Menu.Item
                    key={item.name}
                    icon={item.icon}
                    onClick={() => setIsDownloadReceivingItemsModalVisible(true)}
                  >
                    {item.label}
                  </Menu.Item>
                )
              }

              if (item.submenu) {
                return (
                  <Menu.SubMenu
                    key={item.name}
                    title={
                      <>
                        {item.icon}
                        <span>{item.label}</span>
                      </>
                    }
                  >
                    {item.submenu.map((submenuItem) => {
                      return displaySubMenu(submenuItem);
                    })}
                  </Menu.SubMenu>
                );
              }

              return (
                <Menu.Item
                  key={item.name}
                  icon={item.icon}
                  onClick={() => history.push(item.route)}
                  style={{
                    color: item.display_support_ticket_count && unseenTicketsCount > 0 ? "red" : "white"
                  }}
                >
                  {item.label}
                  <If condition={item.display_support_ticket_count && unseenTicketsCount > 0}>
                    &nbsp;[{unseenTicketsCount}]
                  </If>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
        <Layout>
          <Header>
            <Row justify="space-between" align="middle">
              <Col>
                <Choose>
                  <When condition={collapsed}>
                    <MenuUnfoldOutlined
                      onClick={() => setCollapsed(!collapsed)}
                    />
                  </When>
                  <Otherwise>
                    <MenuFoldOutlined onClick={() => setCollapsed(!collapsed)} />
                  </Otherwise>
                </Choose>
              </Col>
              <Col>
                <Dropdown
                  arrow
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      <Menu.Item key="logout" onClick={() => logout()}>
                        <Space>
                          <LogoutOutlined />
                          <p className="text-xl">Logout</p>
                        </Space>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Space className="header-menu">
                    <Avatar>{accountData.initials}</Avatar>
                    <p className="text-xl">{accountData.firstName + " " + accountData.lastName}</p>
                  </Space>
                </Dropdown>
              </Col>
            </Row>
          </Header>
          <Content>
            <div className="feature-container">
              <RenderRoutes routes={routes} />
            </div>
          </Content>
        </Layout>
      </Layout>
      <StartDownloadModal
        isModalOpen={isDownloadReceivingItemsModalVisible}
        setIsModalOpen={setIsDownloadReceivingItemsModalVisible}
        receivingDownload={true}
      />
    </>
  );
};

export default AccountHook(DashboardLayout);

import { Skeleton, Space } from "antd";

const SkeletonModal = ({
  isLoading
}) => {

  return (
    <>
      <Space>
        <Skeleton.Button active={isLoading} size={"large"} />
        <Skeleton.Input active={isLoading} size={"large"} />
      </Space>
      <br />
      <br />
      <Skeleton.Button active={isLoading} size={"large"} />
      <br />
      <br />
      <Skeleton.Input active={isLoading} size={"large"} />
      <br />
      <br />
      <Skeleton.Input active={isLoading} size={"large"} />
      <br />
      <br />
      <Space>
        <Skeleton.Button active={isLoading} size={"large"} />
        <Skeleton.Input active={isLoading} size={"large"} />
      </Space>
    </>
  )

}

export default SkeletonModal
import { message } from "antd";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { DEVICE_ID } from "./global_constants";
import { v4 as uuid } from "uuid";

const deviceId = localStorage.getItem(DEVICE_ID);
if (!deviceId) {
  const randomUuid = uuid();
  localStorage.setItem(DEVICE_ID, randomUuid);
}

let headers = {
  "Content-Type": "application/json",
  "Client-Type": "web",
  "X-Device-Id": localStorage.getItem(DEVICE_ID),
};

const axiosInstance = axios.create({
  headers,
  timeout: 16000000,
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/`,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let statusCode = error?.response?.status;
    if (statusCode === 403) {
      // check if the response is json and if it contains a "displayable_invalid_device_message" key
      if (error?.response?.data?.displayable_invalid_device_message) {
        // display the error message
        message.error(error?.response?.data?.displayable_invalid_device_message);
        // go to the login page with the message displayed
        // check if the current url is the login page to avoid infinite loop
        if (window.location.pathname !== "/") {
          window.location.href = "/?extra=invalid_device";
        }
      }
    }

    // log error to sentry
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, Checkbox, Card, message, Alert } from "antd";

import { loginUser } from "../../actions";
import { AUTHORIZATION_KEY, DEVICE_ID } from "../../global_constants";

import AccountHook from "../../hooks/account";
import { Link } from "react-router-dom";
import responseErrorService from "../../utilities/responseErrorHandling";
import { getQueryFilteringObjectFromString, getQueryStringValue } from "../../utilities/queryString";

const Login = ({ setAccountData }) => {

  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [isDisplayMultipleDevicesAlert, setIsDisplayMultipleDevicesAlert] = useState(false);

  function onSubmit(values) {
    setProcessing(true);
    loginUser(values)
      .then((response) => {
        setProcessing(false);
        setAccountData(response);
        localStorage.setItem(AUTHORIZATION_KEY, response.token);
        localStorage.setItem(DEVICE_ID, response.device_id);
      })
      .catch((err) => {
        setProcessing(false);
        responseErrorService.handle(err);
      });
  }

  useEffect(() => {
    const extra = getQueryStringValue("extra");
    if (extra) {
      if (extra === "invalid_device") {
        setIsDisplayMultipleDevicesAlert(true);
      }
    }
  }, []);

  return (
    <div className="login-page">
      <Card bordered>
        <p className="text-2xl semi-bold mb-16">Login</p>
        
        <If condition={isDisplayMultipleDevicesAlert}>
          <Alert
            style={{ marginBottom: "15px" }}
            message="Multiple Devices Detected"
            description="We've noticed you're attempting to use the app on multiple devices. Please log in again to continue. Our policy allows only one mobile and one web user per account simultaneously. If you're not using multiple devices of the same type (mobile and web), please contact support for assistance."
            type="warning"
            showIcon
            closable
          />
        </If>

        <Form form={form} onFinish={(values) => onSubmit(values)}>
          <Form.Item
            name="username"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              size="middle"
              prefix={<UserOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              size="middle"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item noStyle>
            <Button
              block
              type="primary"
              className="mt-8"
              htmlType="submit"
              loading={processing}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <p style={
          {
            marginTop: "15px"
          }
        }>Don't have an Account? Click <Link to={"/register"}>Here</Link> to Register new Account</p>
      </Card>
    </div>
  );
};
export default AccountHook(Login);